<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="productmanager" style="width:100vw">
  <div id="b08105b3">
    管理信息
  </div>
  <div id="a6f0a9c1">
    <div id="a729436e">
      <div id="af38a0ae">
        当月交付项目数
      </div>
      <div id="a4e5d636">
        100
      </div>
    </div>
    <div id="e6ac46dd">
      <div id="c4b11211">
        沟通阶段项目数
      </div>
      <div id="c97915ce">
        30
      </div>
    </div>
    <div id="ab9ad687">
      <div id="e773a23b">
        设计阶段项目数
      </div>
      <div id="cb4ad929">
        10
      </div>
    </div>
    <div id="a4bf696c">
      <div id="a401624b">
        开发阶段项目数
      </div>
      <div id="c6c46e6b">
        15
      </div>
    </div>
    <div id="a6f1c8ce">
      <div id="d32fb465">
        空闲项目经理数
      </div>
      <div id="aff66b1e">
        0
      </div>
    </div>
  </div>
  <div id="ace5c72e">
    资源列表
  </div>
  <div id="ab54a3a3">
    话术文档
  </div>
  <img src="../assets/img-a87fd4dc.png" id="a87fd4dc"/>
  <img src="../assets/img-ef825d19.png" id="ef825d19"/>
  <img src="../assets/img-a310d044.png" id="a310d044"/>
  <div id="aff8a6a7">
    宣传手册
  </div>
  <img src="../assets/img-b880f637.png" id="b880f637"/>
  <div id="a0d53af2">
    文章链接
  </div>
  <img src="../assets/img-abf5f740.png" id="abf5f740"/>
  <img src="../assets/img-a5266524.png" id="a5266524"/>
  <img src="../assets/img-abbbb397.png" id="abbbb397"/>
  <img src="../assets/img-a37eff6c.png" id="a37eff6c"/>
  <img src="../assets/img-ae0883f6.png" id="ae0883f6"/>
  <div id="a01837a1">
    演示视频
  </div>
  <img src="../assets/img-a256be81.png" id="a256be81"/>
  <img src="../assets/img-c13a0231.png" id="c13a0231"/>
  <img src="../assets/img-a2e94f55.png" id="a2e94f55"/>
  <img src="../assets/img-a8c05907.png" id="a8c05907"/>
  <div id="aaca29bf">
    案例演示
  </div>
  <div id="a4706bdc">
    <img src="../assets/img-afae7528.png" id="afae7528"/>
    <img src="../assets/img-d394e4c7.png" id="d394e4c7"/>
    <img src="../assets/img-aa59a4ac.png" id="aa59a4ac"/>
  </div>
  <div id="a38ed4d6">
    设计中列表
  </div>
  <div id="fc48e7e0">
    <dal-comp-design id="cmpfc48e7e0">
    </dal-comp-design>
  </div>
  <el-pagination id="a7f05dfd">
  </el-pagination>
  <div id="a7cb8429">
    开发中列表
  </div>
  <div id="dbbf37a7">
    <dal-comp-development id="cmpdbbf37a7">
    </dal-comp-development>
  </div>
  <el-pagination id="a86a47e4">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_comp_design from '../components/dal-comp-design'
import dal_comp_development from '../components/dal-comp-development'
export default{
  components:{
    'dal-comp-design':dal_comp_design,
    'dal-comp-development':dal_comp_development
  },
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#b08105b3{
  padding-top:100px;
}
#b08105b3{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a6f0a9c1{
  width:1323px;
  position:relative;
  margin-top:30px;
  padding-left:163px;
}
#a729436e{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a729436e{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#af38a0ae{
  padding-top:20px;
}
#af38a0ae{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a4e5d636{
  margin-top:18px;
  padding-bottom:21px;
}
#a4e5d636{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#e6ac46dd{
  width:203px;
  position:relative;
  margin-left:77px;
  display:inline-block;
  vertical-align: middle;
}
#e6ac46dd{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#c4b11211{
  padding-top:20px;
}
#c4b11211{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#c97915ce{
  margin-top:18px;
  padding-bottom:21px;
}
#c97915ce{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ab9ad687{
  width:203px;
  position:relative;
  margin-left:77px;
  display:inline-block;
  vertical-align: middle;
}
#ab9ad687{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#e773a23b{
  padding-top:20px;
}
#e773a23b{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#cb4ad929{
  margin-top:18px;
  padding-bottom:21px;
}
#cb4ad929{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a4bf696c{
  width:203px;
  position:relative;
  margin-left:77px;
  display:inline-block;
  vertical-align: middle;
}
#a4bf696c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a401624b{
  padding-top:20px;
}
#a401624b{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#c6c46e6b{
  margin-top:18px;
  padding-bottom:21px;
}
#c6c46e6b{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a6f1c8ce{
  width:203px;
  position:relative;
  margin-left:77px;
  display:inline-block;
  vertical-align: middle;
}
#a6f1c8ce{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#d32fb465{
  padding-top:20px;
}
#d32fb465{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#aff66b1e{
  margin-top:18px;
  padding-bottom:21px;
}
#aff66b1e{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ace5c72e{
  margin-top:60px;
}
#ace5c72e{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ab54a3a3{
  margin-top:30px;
}
#ab54a3a3{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a87fd4dc{
  width:707px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#ef825d19{
  width:505px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a310d044{
  width:505px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#aff8a6a7{
  margin-top:60px;
}
#aff8a6a7{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b880f637{
  width:707px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a0d53af2{
  margin-top:60px;
}
#a0d53af2{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#abf5f740{
  width:707px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a5266524{
  width:606px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#abbbb397{
  width:505px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a37eff6c{
  width:606px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#ae0883f6{
  width:656px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a01837a1{
  margin-top:60px;
}
#a01837a1{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a256be81{
  width:707px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#c13a0231{
  width:606px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a2e94f55{
  width:505px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#a8c05907{
  width:606px;
  height:36px;
  margin-top:30px;
  padding-left:164px;
}
#aaca29bf{
  margin-top:60px;
}
#aaca29bf{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a4706bdc{
  width:1218px;
  position:relative;
  margin-top:30px;
  padding-left:212px;
}
#afae7528{
  width:68px;
  height:68px;
  padding-top:110px;
  padding-bottom:133px;
  display:inline-block;
  vertical-align: middle;
}
#afae7528{
  vertical-align:middle;
}
#d394e4c7{
  width:765px;
  height:311px;
  margin-left:158px;
  display:inline-block;
  vertical-align: middle;
}
#d394e4c7{
  vertical-align:middle;
}
#aa59a4ac{
  width:68px;
  height:68px;
  margin-left:159px;
  padding-top:110px;
  padding-bottom:133px;
  display:inline-block;
  vertical-align: middle;
}
#aa59a4ac{
  vertical-align:middle;
}
#a38ed4d6{
  margin-top:60px;
}
#a38ed4d6{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#fc48e7e0{
  width:1323px;
  margin-top:30px;
  padding-left:163px;
}
#a7f05dfd{
  width:302px;
  margin-top:52px;
  padding-left:666px;
}
#a7cb8429{
  margin-top:60px;
}
#a7cb8429{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#dbbf37a7{
  width:1323px;
  margin-top:30px;
  padding-left:164px;
}
#a86a47e4{
  width:302px;
  margin-top:60px;
  padding-left:667px;
  padding-bottom:77px;
}
/*在此注释下方添加自定义样式*/
</style>
