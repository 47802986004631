<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="dal-comp-design">
  <el-table id="daed134d" :data="tbd_daed134d" stripe="" border="">
    <el-table-column prop="a80b3978" label="项目名称">
    </el-table-column>
    <el-table-column prop="f220618a" label="客户名称">
    </el-table-column>
    <el-table-column prop="a4a79b5e" label="电话号码">
    </el-table-column>
    <el-table-column prop="aac31779" label="电子邮箱">
    </el-table-column>
    <el-table-column prop="c4c2c546" label="项目经理">
    </el-table-column>
    <el-table-column prop="a14651cd" label="开始时间">
    </el-table-column>
    <el-table-column prop="aac78a78" label="已耗时">
    </el-table-column>
    <el-table-column prop="a3b5ff43" label="备注">
    </el-table-column>
  </el-table>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_daed134d:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#daed134d{
  width:1323px;
}
#daed134d{
  display:inline-block;
  vertical-align: middle;
}
/*在此注释下方添加自定义样式*/
</style>
