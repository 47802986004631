<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="dal-comp-development">
  <el-table id="edcde7f5" :data="tbd_edcde7f5" stripe="" border="">
    <el-table-column prop="a21a8e7c" label="项目名称">
    </el-table-column>
    <el-table-column prop="a19531f3" label="客户名称">
    </el-table-column>
    <el-table-column prop="a7174f3e" label="产品经理">
    </el-table-column>
    <el-table-column prop="cef7aea3" label="项目经理">
    </el-table-column>
    <el-table-column prop="a00a9187" label="项目金额">
    </el-table-column>
    <el-table-column prop="df589972" label="设计开始时间">
    </el-table-column>
    <el-table-column prop="f6f0d406" label="项目开始时间">
    </el-table-column>
    <el-table-column prop="a12f91d7" label="当前阶段">
    </el-table-column>
    <el-table-column prop="a6010ee9" label="预交付时间">
    </el-table-column>
    <el-table-column prop="afd99a9d" label="倒计时">
    </el-table-column>
  </el-table>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_edcde7f5:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#edcde7f5{
  width:1323px;
}
#edcde7f5{
  display:inline-block;
  vertical-align: middle;
}
/*在此注释下方添加自定义样式*/
</style>
